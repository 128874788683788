/* compileCompressed: ../../../public/assets/css/front.min.css*/
/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
Created on : 2015-12-29, 14:19:13
Author     : Frausiol
*/

/* IMPORT CONSTANTS FILE */
@import "_constans.scss";
@import "_reset.min.css";

 
$form_grey: #424B50;
$form_placeholder_grey: #595f65;
@mixin mk-input() {
  width: 100%;
  background-color: rgba(255,255,255,0.8);
  padding: 12px 5px;
  line-height: 125%;

  border-radius: 0;
  /*@include border(null, 1px, solid, #ddd, 1);*/
  border: 1px solid transparent !important;

  &:focus {
    outline: 0;
    border: 1px solid $light_orange_color !important;
  }

  &:-ms-input-placeholder {
    color:$form_placeholder_grey;
    font-size: 0.9em;
  }
  &::-webkit-input-placeholder {
    color:$form_placeholder_grey;
    font-size: 0.9em;
  }

  &:-moz-placeholder {
    color:$form_placeholder_grey;
    font-size: 0.9em;
  }
  &::-moz-placeholder {
    color:$form_placeholder_grey;
    font-size: 0.9em;
  }
}

@mixin mk-label(){
  font-size: 1em;
  color: $form_grey;
  margin: 10px 0 5px 0;
  font-weight: 600;
}

/* END import constans file*/
html, body {
  width: 100%;
  height: 100%;
  position: relative;
}

body {
  font-family: $standard_font_family;
  font-size: $standard_font_size;
  line-height: 110%;
}

.section {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.vert_middle {
  display: table-cell;
  vertical-align: middle;
}

.fullpage_container {
  z-index: 5;
}
.main-header {

  display: none;

}

button {
  padding: 12px 2px !important;
  i {
    position: absolute;
    left: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

.legend {
  padding: 5px 15px 5px 0;
  text-align: right;
  font-size: 12px;
}

#slides {

  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;

  .slides_container {

    width: 100%;
    height: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: #fff;
      opacity: 0.4;

    }
  }

}

/*
FORMS - PANEL
*/
form {

  label {
    line-height: 100%;
    margin: 0 0 6px 0;
  }

  input, select, textarea {

    border-radius: 0;
    @include border(null, 1px, solid, #ddd, 1);

    &:focus {
      outline: 0;
      border: 1px solid $light_orange_color !important;
    }

  }

  textarea {
    max-width: 100%;
    min-width: 100%;
  }

  .select2-container--default input:focus {
    border: none !important;
  }

}

.select-icon {
  max-width: 32px;
  vertical-align: middle;
  margin-right: 10px;
}

/*
HEADER
*/
#select-wrapper {
  #currency-selector {
    border: none;
    cursor: pointer;
    color: #000;
    background: rgba(255,255,255,0);
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    *:focus, *:active {
      border: 0px solid;
      outline: 0;
      background: rgba(255,255,255,0);
    }
  }
}
#select-wrapper:hover {
  color: #ff7100;
  -webkit-transition-duration: .5s;
  transition-duration: .5s;
}

#site-header {

  margin: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 102;

  &.afterAnimate {
    &:before {
      width: 100%;
    }
  }

  &.fixed {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    margin: 0;
    max-width: 100%;
    padding: 15px;
    height: 87px;
    z-index: 101;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background: $orange_color;
      @include animation('border-line 2s 3');
      -webkit-animation-iteration-count: 1;
              animation-iteration-count: 1;
      -webkit-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
    }

  }

  #site-name {
    margin: 0;
    padding: 0;
    display: block;
    position: relative;
    z-index: 1;
    width: 170px;
    height: 50px;
    float: left;

    a {
      display: block;
      width: 100%;
      height: 100%;
      background: url(../img/logo.png) center center no-repeat;
      background-size: 100%;
      text-indent: -999em;
    }

  }

  #site-subname {

    max-width: 280px;
    width: 100%;
    height: 32px;
    background: url(../img/move.png) center center no-repeat;
    background-size: 100%;
    text-indent: -999em;
    float: left;
    margin: 10px 25px;

  }

  #proof-of-concept {

    max-width: 160px;
    left: 200px;
    top: 5px;
    width: 100%;
    height: 24px;
    background-color: rgba(247, 201, 18, 1);
    position: absolute;
    z-index: 10;
    display: inline-block;
    transform: rotate(-7deg);
    text-align: center;
    border-radius: 20px/90%;
    box-shadow: -9px 10px 5px 0px rgba(0,0,0,0.33);
    padding: 4px 0;

    .text-proof {

      position: relative;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: bold;

    }
    

  }

  #site-navigation {
    float: right;
    background: transparent !important;
    border: 0;
    z-index: 0;

    .nav {

      & > li {

        display: inline-block;
        vertical-align: middle;
        line-height: 50px;
        float: none;

        a {
          padding: 0 4px;
          line-height: 50px;
        }

        &:nth-last-child(5) {
          /*margin-left: 1em;*/
        }

        &.icon {

          padding: 9px 15px 0 15px;

          a {
            width: 22px;
            height: 22px;
            display: block;
            border: none;
            text-indent: -999em;
            -webkit-transition-duration: .5s;
                    transition-duration: .5s;
            padding: 0;

            &:hover {
              border: none;
              -webkit-transition-duration: .5s;
                      transition-duration: .5s;

            }

          }

        }

        &.facebook {

          a {

            background: url(../img/facebook.png);
            background-size: 100%;

            &:hover {

              border: none;
              background: url(../img/facebook_active.png);
              background-size: 100%;

            }

          }

        }
        &.twitter {

          a {

            background: url(../img/twitter.png);
            background-size: 100%;

            &:hover {

              border: none;
              background: url(../img/twitter_active.png);
              background-size: 100%;

            }

          }

        }

        &.linkedin {

          a {

            background: url(../img/linkedin.png);
            background-size: 100%;

            &:hover {

              border: none;
              background: url(../img/linkedin_active.png);
              background-size: 100%;

            }

          }

        }



        &.panel_icon {

          padding: 0 8px;

          &.open {
            background-color: #fa6;
            a {
              background: none;
            }
          }

          a {
            padding: 0;
            font-size: 1.25em;
            -webkit-transition-duration: .5s;
                    transition-duration: .5s;
            display: block;
            line-height: 50px;
            border: none !important;

            &:hover {
              border: none;
              color: $orange_color;
              -webkit-transition-duration: .5s;
                      transition-duration: .5s;
            }

            i {
              display: inline-block;
              vertical-align: middle;
              line-height: 50px;
            }

            span {
              font-size: 13px;
              display: inline-block;
              margin-left: 10px;
              vertical-align: middle;
            }

          }

        }

        &.instagram {

          a {

            background: url(../img/insta.png);
            background-size: 100%;

            &:hover {

              border: none;
              background: url(../img/insta_active.png);
              background-size: 100%;

            }

          }

        }

        a {

          font-size: 14px;
          font-weight: 200;
          text-transform: uppercase;
          color: #000;
          border-bottom: 1px solid transparent;

          &:hover {
            border-bottom: 1px solid $light_orange_color;
          }

        }

      }

      #user_menu{

        margin: 0;
        border:0;
        background-color: $light_orange_color ;
        right:0;

        .user-header{
          background-color: $light_orange_color ;
          border: 0;

          img{
            border: 0;
          }

          p{
            color:#fff;
            line-height: 1.2;
            /*text-shadow: 1px 1px black;*/

            small{
              margin-top: 5px;
              text-shadow:none;
            }
          }

        }
        .user-footer{
          background-color: #fff ;
          /*border: 2px solid rgba($orange_color, 0.5);*/
          border-top:0;
          border-bottom:0;
          padding:0 !important;

          a{
            font-weight: 800;
            width:100%;
            text-align: center;
            margin: 0;
            color: $light_orange_color;
            line-height: 1;
            padding: 15px;
            font-size: 11.2px;
            font-weight: 800;
            border-bottom: 2px solid #fa6 !important;
          }
          a:hover{
            width:100%;
            text-align: center;
            margin: 0;
            color: #fff;
            background-color: $light_grey_color;
          }

          /*                a.btn.btn-own-dark-orange.btn-flat.text_color_grey{
          color: #fff;
          width: 100%;
          background-color: $light_grey_color;
        }*/

      }
    }

  }


}


&:after {
  content: "";
  display: table;
  clear: both;
}

}





/*
SECOND SECTION
*/
.second_section {

  width: 100%;
  position: relative;
  background: url(../img/slider-bg.jpg) -50px center no-repeat;

  *:focus { outline: 0; }

  .once {

    display: table !important;
    position: relative;

  }

  .slide_content {

    max-width: 50%;
    margin: 0 auto;
    padding: 25px;
    background: rgba(255, 255, 255, .4);

  }

  .slide_image {
    position: absolute;
    left: 85%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: auto;
    }
  }

  h2 {
    display: inline-block;
    font-size: 1.6em;
    font-weight: bold;
    letter-spacing: 2px;
    line-height: 100%;
    color: #000;
    text-transform: uppercase;
    padding: 0 0 10px 0;
    border-bottom: 4px solid $orange_color;
  }

  p {
    font-size: 1em;
    font-weight: bold;
    color: #000;
    padding: 30px 0 0 0;
    line-height: 130%;
  }

  .slider_dots {

    position: absolute;
    top: 80%;
    left: 50%;
    -webkit-transform: translate(-50%, -100%);
            transform: translate(-50%, -100%);

    li {
      display: inline-block;
      margin: 0 30px;
    }

    button {

      width: 20px;
      height: 20px;
      @include border(null, 2px, solid, $orange_color, .5);
      background: transparent;
      box-shadow: none;
      text-indent: -999em;
      border-radius: 50%;
      position: relative;
      padding: 0 !important;

    }

    .slick-active {

      button {

        &:before {

          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: $orange_color;
          position: absolute;
          left: 5px;
          top: 5px;

        }

      }

    }

  }

}

/* VIDEO */

.video_content {
  width: 100%;

  video {
    width: 100%;
  }

}


/*
SECOND SECTION - VIDEO
*/
.third_section {

  .section_header {
    font-size: 3rem;
    font-weight: bold;
    line-height: 100%;
    color:  $orange_color;
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0 45px 0;
  }

  video{
    -o-object-fit: cover;
       object-fit: cover;
  }

  .video_thumbnail {
    width: 60%;
    float: right;
    cursor: pointer;
    box-shadow: 0px 0px 35px 3px rgba(0,0,0,0.14);
    z-index: 1;
    position: relative;

    img {
      width: 100%;
      height: auto;
    }

    &.pause:after { display: none; }

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: url(../img/play_button.png) center center no-repeat;
      background-size: 64px 64px;
    }

  }

  .hw_information {
    text-align: center;
    width: 80%;
    float: left;
    padding: 30px;
    @include border(null, 4px, solid, $orange_color, 1);
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);

    .headers {
      max-width: 50%;
      padding: 0 15px 0 0;
    }

    h3 {
      font-size: 1.1em;
      font-weight: bold;
      color: #000;
      line-height: 160%;
    }

    .action {

      margin: 60px 0 0 0;

      h3 {
        font-size: 1.2rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $orange_color;
        line-height: 160%;
        max-width: 50%;
        float: left;
      }

      a {

        display: block;
        float: right;
        margin-top: 15px;
        margin-left: 15px;
        min-width: 280px;

        button {
          min-width: 200px;
          background: transparent;
          font-size: 1em;
          font-weight: bold;
          display: block;
          color: $orange_color;
          padding: 10px 20px !important;
          text-transform: uppercase;
          border: 4px solid $orange_color;
          text-align: center;
          margin-top: 0;
          width: 100%;

        }

      }

      &:after {
        content: "";
        display: table;
        clear: both;
      }

    }

  }

}

.transport_information {

  position: relative;
  background: url(../img/features_bg2.png) 15% center no-repeat;
  background-size: 25%;

  &:before {
    /*        content: "";
    width: 35px;
    height: 4px;
    background: $orange_color;
    position: absolute;
    top: 15%;
    left: 48%;*/
  }

  h3{
    text-align: center;
    color: #ff7100;
    font-size: 3em;
    text-transform: uppercase;
    font-weight: 600;
  }

  .transport_inf_list {

    li {

      &:first-of-type {
        margin: 170px 0 50px 0;
      }

      margin: 50px 0;

      h2 {

        font-size: 1.6em;
        font-weight: bold;
        color: $orange_color;
        letter-spacing: 5px;
        margin: 0 0 15px 0;
        text-transform: uppercase;
        line-height: 100%;

      }

      p {

        font-size: 1em;
        color: #000;
        line-height: 150%;

      }

    }

  }

}

.who {

  .header_s {
    background: $orange_color;
    padding: 25px 0;
  }

  h2 {

    font-size: 3em;
    font-weight: bold;
    line-height: 100%;
    text-transform: uppercase;
    color: #fff;

  }

}

.who_we_are {

  position: relative;

  &:after {
    content: "";
    background: url(../img/podroznik4-mirror.png) right center no-repeat;
    background-size: 27%;
    width: 100%;
    height: 100%;
    right: 7%;
    top: 0;
    position: absolute;
  }

  .circle {
    width: 25%;
    position: absolute;
    background: #ff7100;
    border-radius: 50%;
    right: 7%;
    top: 50%;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }

  .circle:before {
    content: "";
    display: block;
    padding-top: 100%;
  }

  @media only screen and (max-width: 992px) {
    &:after {
      content: none;
    }

    .circle {
      display: none;
    }
  }

  &:before {
    content: "";
    background: url(../img/hwa_pattern.png) -70px center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: .6;
  }

  p {
    font-size: 1.1em;
    font-weight: bold;
    line-height: 150%;
    color: #000;
    font-style: italic;
    text-align: justify;

    &:nth-of-type(2){
      font-style: normal;
      font-size: 1.5em;
      text-align: right;
    }
  }

}
/* IN NUMBERS SECTION*/
.in_numbers {

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  *:focus { outline: 0; }

  background: $orange_color;
  padding: 50px 0 0 0;

  .section_header {
    padding: 0 0 35px 0;

    h2 {
      font-size: 3em;
      line-height: 100%;
      font-weight: bold;
      text-transform: uppercase;
      color: #fff;
      letter-spacing: 5px;
    }

  }

  .in_numbers_carousel {
    background: transparent;
    overflow: hidden;
    width: 100%;
  }

  .inn_carousel {

    padding: 50px 0;

    .slick-slide {
      -webkit-transition: ease-in-out all .15s ease;
      transition: ease-in-out all .15s ease;
      -webkit-transition-duration: .4s;
              transition-duration: .4s;
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      background-color: rgba(0, 0, 0, 0.75);
      position: relative;
      opacity: 0;
      height: auto;

      img {
        display: block;
        width: 100%;
      }

    }

    .slick-slide.slick-active {
      opacity: .4;
    }

    .slick-current {
      -webkit-transform: scale(1.25, 1.25);
              transform: scale(1.25, 1.25);
      -webkit-transition: ease-in-out all .15s ease;
      transition: ease-in-out all .15s ease;
      -webkit-transition-duration: .4s;
              transition-duration: .4s;
      z-index: 2;
      overflow:hidden;
      opacity: 1 !important;
      box-shadow: 0px 0px 85px -20px rgba(0,0,0,0.75);

      .headers {
        text-align: center;
        background: rgba(0,0,0,0.5);
        padding: 7px;

        h2 {
          margin: 10px auto 10px auto;
        }
      }

    }

    .slick-current:hover {
      cursor: pointer;
    }

    .slide {
      background: transparent;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: .4;
    }

    .slick-list {
      overflow: visible;
    }

    .headers {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: left;

      h2 {
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 100%;
        color: #fff;
        margin: 0 0 10px 15px;
        max-width: 70%;
      }

      h3 {
        font-family: 'Montserrat', 'Roboto', sans-serif;
        font-weight: 700;
        font-size: 6rem;
        line-height: 73%;
        color: #fff;
        margin: 0 0 0 15px;
      }

    }

  }

  .inn_carousel_dots {

    margin-top: 50px;

    .arrow_nav {

      position: relative;

      .prev {
        display: block;
        width: 29px;
        height: 25px;
        background: url(../img/slider_arrow.png) center center no-repeat;
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
        cursor: pointer;
        position: absolute;
        left: 0;
        top: -11px;
      }

      .next {
        display: block;
        width: 29px;
        height: 25px;
        background: url(../img/slider_arrow.png) center center no-repeat;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: -11px;
      }

    }

    .slick-dots { text-align: center; }

    li {
      display: inline-block;
      margin: 0;
    }

    button {
      width: 60px;
      height: 4px;
      background: rgba(255, 255, 255, .4);
      border: none;
      box-shadow: none;
      text-indent: -999em;
      padding: 0 !important;
    }

    .slick-active {
      button {
        background: rgba(255, 255, 255, 1);
      }
    }

  }

  .bottom_numbers {

    margin: 50px 0 30px 0;

    h2 {
      font-family: 'Montserrat', 'Roboto', sans-serif;
      font-size: 3rem;
      font-weight: 700;
      color: #fff;
      line-height: 100%;
    }

    h3 {
      font-size: .9rem;
      line-height: 100%;
      color: rgba(255, 255, 255, .6);
    }

  }

}

/*
CLIENTS REVIEW
*/
.clients_rev {

  width: 100%;
  position: relative;
  margin: 50px 0 0 0;

  h2 {
    font-size: 3rem;
    font-weight: normal;
    line-height: 100%;
    text-transform: uppercase;
    color: #000;
  }

  .header_c {

    padding: 0 0 55px 0;
    border-bottom: 5px solid $orange_color;
    margin-bottom: 100px;

  }

  .nav {

    li {

      margin: 0 5px;

      a {

        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        border: 2px solid lightgrey;
        border-radius: 50px;
        padding: 10px 20px;
        text-align: center;
        max-width: 150px;

      }

    }

  }

  .review_list {

    max-width: 80%;
    margin: 0 auto;

    *:focus { outline: 0; }

    .slick-slide {
      height: auto;
    }

    .once {

      .wrp {

        max-width: 80%;
        border-bottom: 2px solid $orange_color;
        border-right: 2px solid $orange_color;
        /*border-bottom-right-radius: 2px;*/
        position: relative;
        padding: 10px;
        margin: 45px auto;

        &:before {

          content: "";
          position: absolute;
          right: 30px;
          bottom: -45px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 70px 45px 0;
          border-color: transparent $orange_color transparent transparent;

        }

        &:after {

          content: "";
          position: absolute;
          right: 32px;
          bottom: -40px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 66px 42px 0;
          border-color: transparent #fff transparent transparent;

        }

      }

      &:first-child {
        margin-left: 0;
      }

    }

    .thumbnail {

      width: 64px;
      height: 64px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center center !important;
      display: block;
      border-radius: 50%;
      overflow: hidden;
      float: left;
      margin: 0 15px 0 0;

    }

    .rev_content {

      &:after {
        content: "";
        display: table;
        clear: both;
      }

      h3 {

        font-size: 1.1em;
        font-weight: 600;
        line-height: 100%;
        margin-bottom: 10px;

      }

      p {

        font-size: 1em;
        font-weight: 400;
        letter-spacing: 1px;

      }

    }

  }

}

.partners {

  background: #fff;
  position: relative;
  padding: 40px 0 160px 0;

  &:after {
    content: "";
    width: 120px;
    left: 45%;
    bottom: 100px;
    position: absolute;
    height: 4px;
    background: $orange_color;
  }

  h2 {
    font-size: 4em;
    font-weight: normal;
    line-height: 100%;
    text-transform: uppercase;
    color: $orange_color;
  }

  .header_c {

    background: url(../img/partner-guy2.png) center bottom no-repeat;
    padding: 156px 0 35px 0;
    border-bottom: 5px solid $orange_color;
    margin-bottom: 100px;

  }

  .nav {

    li {

      margin: 0 5px;

      a {

        font-size: 1em;
        font-weight: bold;
        text-transform: uppercase;
        border: 2px solid $orange_color;
        border-radius: 50px;
        padding: 10px 20px;
        text-align: center;
        max-width: 150px;
        color: $orange_color;

        &:hover{
          color: $orange_color;
          background-color: #fff;
          border-color: #666;
          color: #666;
        }

      }

    }

  }

  .partners_list {
    .slick-slide {
      height: auto;
    }
    li {
      text-align: center;
    }
    a {
      display: inline-block;
      text-align: center;
    }

    img{
      padding: 10px;
      background-color: rgba($orange_color ,.5);
    }

  }

}

/* CONTACT SECTION */
.contact {
  margin-top: 100px;

  background: url(../img/bg-gradient.jpg) center center no-repeat;
  background-size: cover;
  background-color: $orange_color;
  position: relative;

  h2 {
    font-size: 3em;
    font-weight: bold;
    line-height: 100%;
    color: #fff;
    letter-spacing: 1px;
    /* text-align: center;*/
    text-transform: uppercase;
    margin: 0 0 100px 0;
  }

  h3 {

    font-size: 1em;
    font-weight: normal;
    line-height: 140%;
    color: rgba(255, 255, 255, .6);

    &:last-of-type {
      margin: 0 0 25px 0;
    }

  }

  form {

    .form-group {
      margin: 0 0 15px 0;
    }

    ::-webkit-input-placeholder {
      color: #fff;
      font-size: .8em;
      font-weight: normal;
    }
    ::-moz-placeholder {
      color: #fff;
      font-size: .8em;
      font-weight: normal;
    }
    :-ms-input-placeholder {
      color: #fff;
      font-size: .8em;
      font-weight: normal;
    }
    :-moz-placeholder {
      color: #fff;
      font-size: .8em;
      font-weight: normal;
    }

    input {

      border: none;
      background-color: rgba(255, 255, 255, .2);
      padding: 10px 10px 10px 20px;
      min-height: 60px;
      color: #fff;
      font-size: .9em;

      &:focus {
        outline: 0;
        border: none;
      }

      &#firstName {
        /*background-image: url(../img/icon_name.png);*/
        background-position: 30px center;
        background-repeat: no-repeat;
      }

      &#email {
        /*background-image: url(../img/icon_email.png);*/
        background-position: 30px center;
        background-repeat: no-repeat;
      }

    }

    textarea {

      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      padding: 20px 10px;
      color: #fff;
      font-size: .9em;
      width: 100%;
      max-width: 100%;
      min-width: 100%;

      &:focus {
        outline: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(244, 72, 5, .2);
        box-shadow: none;
      }

    }

    button {
      margin-top: 25px;
      padding: 21px 0 !important;
      background: url(../img/icon_send.png) 30px center no-repeat;
    }

  }

  .map_contact {
    position: absolute;
    right: 0;
    bottom: 0;
    background: url(../img/contact_bg.jpg) center center no-repeat;
    background-size: cover;
    display: table;


/*    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0,0,0,.2);
    }*/

    h2 {
      position: absolute;
      z-index: 9;
      right: 5%;
      bottom: 5%;
      /*background: url(../img/contact-guy.png) right top no-repeat;*/
      letter-spacing: 2px;
      /*padding: 240px 0 0 0;*/
    }

  }

}

.landing {

  padding: 100px 0 0 0;

  .user_type {

    text-align: center;
    @include background($orange_color, .8);
    -webkit-transition-duration: .3s;
            transition-duration: .3s;
    color: #fff;
    margin-bottom: 16px;

    &:hover {
      @include background($orange_color, 1);
      -webkit-transition-duration: .3s;
              transition-duration: .3s;
    }

    a {
      display: block;
      text-transform: uppercase;
      font-size: 2em;
      background-color: transparent !important;
      padding-top: 100%;
      position: relative;
      overflow: hidden;
    }

    .user_type_info {
        line-height: 1em;
      position: absolute;
      top: 50%;
      width: 100%;
      left: 50%;
      opacity: 1;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
      -webkit-transition: all .4s ease;
      transition: all .4s ease;

      span{
          font-size: .5em;
          display: block;
          line-height: 1.1em;
          padding-top: 20px;
      }
    }

    .info_for_user{
      font-size: .7em;
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 0;
      opacity: 0;
      width: 100%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
      text-transform: none;
      -webkit-transition: all .4s ease;
      transition: all .4s ease;
      line-height: 1.4;
      @media (max-width: 767px) {
        font-size: 16px;
      }
      @media (max-width: 1280px) {
        font-size: 18px;
      }
    }

    &:hover{
      .info_for_user{
        opacity: 1;
      }
      .user_type_info{
        opacity: 0;
      }
    }

    &:nth-child(1) {
      .user_type_info {
        left: 50%;
      }
      .info_for_user {
        left: 100%;
      }
      &:hover {
        .user_type_info {
          left: 0;
        }
        .info_for_user {
          left: 50%;
        }
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        margin-right: 8px;
      }
    }

    &:nth-child(2) {
      .user_type_info {
        left: 50%;
      }
      .info_for_user {
        left: 0;
      }
      &:hover {
        .user_type_info {
          left: 100%;
        }
        .info_for_user {
          left: 50%;
        }
      }
      @media (min-width: 768px) and (max-width: 1199px) {
        margin-left: 8px;
      }
    }

  }

  .landing_information{
    text-align: center;
    padding: 15px;
    font-size: 1.25em;
    background-color: rgba(255,255,255, .7);
    margin-top: 20px;
    line-height: 150%;
    font-weight: 600;
    @media (max-width: 1280px) {
      font-size: 18px;
    }

  }



}

@include keyframes(border-line) {
  0% { width: 0%; }
  100% { width: 100%; }
}

.panel_header {
  display: inline-block;
  width: 100%;
  font-size: 26px;
  line-height: 100%;
  font-weight: 800;
  margin: 12px 0 24px 0;
  text-align: center;
  text-transform: uppercase;
}

.form_container,
.register_information {

  @include background(#fff, .7);
  padding: 25px 15px;

}

.register_information {

  h3 {
    font-weight: 600;
    line-height: 130%;
    margin: 0 0 6px 0;
  }

  h2{
    font-weight: 600;
    line-height: 130%;
    font-size: 1.2em;
    margin-bottom: 15px;
    text-align: center;

    &.offerer_register_need{
        margin-top: 20px;
        text-align: left;
        font-size: 1em;
    }
  }

  ul {

    margin: 0 0 0 35px;
    list-style: square;

    li {
      font-size: .94em;
      line-height: 130%;
      margin: 0 0 6px 0;
    }

  }

}

.icheckbox_square-orange{
  float: left;
}

/*offerer register form mk*/
.offerer-register{
  padding-top: 52px;
}

.commerce_offerer{
  display: none;
}

.offerer-register-form{

  input, select
  {
    @include mk-input();
  }

  .input_group label{
    /*margin: 10px 0;*/
  }
  .offerer_radio_inputs {
    margin: 5px 0;
    label{
      color: $orange_color;
    }
  }

  .offerer_checkbox_inputs{

    .icheckbox_square-orange{
      margin-right:5px;
    }
  }

  /*        select{
  padding-top: 8px;
  padding-bottom: 7px;
}*/

label{
  @include mk-label();
}


.mk_required{
  font-size: 0.9em;
  padding: 20px 0;
}

.mk_submit_container{
  margin-top:10px;
  button{
    font-size: 1em;
  }
}


}
.allready_have_account_button{
  margin-top: 20px;
}

.fix-reg-margin{
    margin-top: 3px !important;
}

.fix-trav-margin{
    margin-top: 10px;
}

.reg_link{
    color: #424B50;
    text-decoration: underline;
    transition: all 0.3s;
    
    &:hover{
        opacity: 0.7;
    }
}

/*
HAMBURGER ICON
*/
#nav-toggle { width: 35px; display: block; cursor: pointer; padding: 10px 35px 16px 0px; position: absolute; right: 15px; top: 27px; }
#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: black;
  position: absolute;
  display: block;
  content: '';
}
#nav-toggle span:before {
  top: -10px;
}
#nav-toggle span:after {
  bottom: -10px;
}

#nav-toggle span, #nav-toggle span:before, #nav-toggle span:after {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}
#nav-toggle.active span {
  background-color: transparent;
}
#nav-toggle.active span:before, #nav-toggle.active span:after {
  top: 0;
}
#nav-toggle.active span:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
#nav-toggle.active span:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

/*-----------

Landing Page search form

-----------*/

/*
HOME SECTION (1)
*/
#home {

  display: table;
  width: 100%;
  height: 100%;
  position: relative;

} /*end home*/

.vert_middle {
  padding-bottom: 10px;
  display: table-cell;
  vertical-align: middle;
  /*position: absolute;*/
  /*    left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);*/
  width: 100%;
}

#home_background{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

}
.background_slide{
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#home_background_overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #fff;
  opacity: 0.4;
}

#home_background .slick-list{ /*fix for IE*/
  height: 100vh;
}

.register_search_button{
  -webkit-transition: all .5s;
  transition: all .5s;
  white-space: normal;
}

.register_search_button_margin{
  margin-top: 20px;
}

#login_button{
  margin-top: 20px;
}

.mk-form-outer-container{

  input {
    -webkit-box-shadow: none;
    outline: -webkit-focus-ring-color auto 0px;
  }
  width: 100%;
  .mk-form-head{
    padding-bottom: 15px;

    h2{
      color: $orange_color;
      text-align: center;
      font-weight: 600;
      font-size: 1.5em;
      margin-bottom: 5px
    }
  }


  ul{
    border:0;

    &.nav.nav-tabs{

      li{
        padding: 0;
        text-align: center;

        a{
          background-color: rgba(255,255,255,0);
          border: 0;
          color: $form_grey;
          font-weight: 600;
          font-size: 1.2em;

          &:hover{
            color: $orange_color;
          }
        }

        &.active{
          border-top: 4px solid $orange_color;

          a{
            color: $orange_color;
            font-weight: 600;
          }
        }
      }
    }
  }

  .mk-form{
    margin-top: 82px;
    background-color: rgba(255,255,255,0.5);
    padding-bottom: 10px;

    input, select
    {
      @include mk-input();
    }

    select{
      padding-top: 8px;
      padding-bottom: 7px;
    }

    label{
      @include mk-label();
    }

    .mk_radio_inputs{

      padding: 12px 0 5px 10px;

      label{
        color:$orange_color;
        font-size: 1em;
        padding-left: 2px;
      }
    }

    .mk-date-time{
      padding: 0 10px;
    }

    .mk_submit_container{

      button{
        width: 100%;
        background-color: $orange_color;
        border: 0;
        color: #fff;
        padding: 12px 5px;
        font-size: 1em;
      }

      button:hover{
        background-color: $orange_color;
      }

      button::before {
        position:absolute;
        font-family: FontAwesome;
        font-weight: 200;
        font-size: 1.5em;
        color: #fff;
        top:0.4em;
        left:25px;
        /*margin-left: 1em;*/
        margin-top: 0.5em;
        content: "\f002";
      }

    }
    .mk_required{
      font-size: 0.9em;
      padding: 20px;
    }

    .portal_information{
      text-align: center;
      font-size: 1.2em;
      font-weight: 600;
      line-height: 110%;
    }

    .local_rent_type_select{
      .select2-selection.select2-selection--single{
        width: 100%;
        background-color: rgba(255,255,255,0.8);
        padding: 12px 5px !important;
        line-height: 125%;
        border-radius: 0;
        height: 45px;
        border: 1px solid #ddd;
      }

      .select2-selection__rendered{
        /*line-height: 43px;*/
        padding-left: 0;
      }

      .select2-selection__arrow{
        top: 10px;
      }
    }

    .select2-container{
        z-index: 1;
    }

  }
  .mk_register_and_add{
    margin-top: 20px;
    button{
      width: 100%;
      background-color: $orange_color;
      border: 0;
      color: #fff;
      padding: 12px 5px;
      font-size: 1em;
    }
    button:hover{
      background-color: #ff851b;
    }
  }

  .search_additional_info{
    text-align: center;
    margin-top: 82px;
    max-width: 70%;
    /* right: 0; */
    /* margin: 0; */
    margin-right: 0;
    margin-left: auto;
    padding: 15px 10px;
    background: rgba(255,255,255,.5);

    &.search_additional_info_local{
       margin-right: auto;
       margin-left: 0;
    }

    h3{
        text-align: center;
        font-weight: 600;
        margin-bottom: 10px;
    }

    p{
        font-size: 1em;
        line-height: 110%;
    }

    }

}

.vert_middle.login_form form{

  input, select
  {
    @include mk-input();
  }
  label{
    @include mk-label();
  }

  .mk_required{
    padding: 20px 0;
  }
  .forgot_password{
    line-height: 130%;
  }
}

.parsley-errors-list{
  margin: 5px 0;
  font-size: .8em;
  color: $orange_color;
  display: block;
}

.accept_terms_container{
    .parsley-errors-list{
        float: right;
        width: 100%;
    }
}

/* DATEPICKER */
.datepicker {
  display: block !important;
}

.bootstrap-datetimepicker-widget * { border-radius: 0 !important; }
.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: $orange_color !important;
}
.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td span.active {
  background: $orange_color;
  -webkit-transition: background .5s ease-out;
  transition: background .5s ease-out;

  &:hover {
    background: $light_orange_color;
    -webkit-transition: background .5s ease-out;
    transition: background .5s ease-out;
  }

}
.bootstrap-datetimepicker-widget{
  border: 1px solid rgba($light_grey_color, 0.5);
}

.bootstrap-datetimepicker-widget .timepicker {

  .table-condensed {

    tr, td{
      border:0 !important;
    }

    span.fa{
      color: $orange_color;
      font-size: 1.5em;
    }
  }
}

/* clockpicker*/
.clockpicker-input {
  cursor: text !important;
}

.clockpicker-popover{



  .clockpicker-canvas{

    svg{

      line{
        stroke: rgba($orange_color, 0.5);
      }

      .clockpicker-canvas-fg{
        fill: $orange_color;
      }
      .clockpicker-canvas-bg{
        fill: $light_orange_color;
      }

      .clockpicker-canvas-bearing{
        fill: $light_orange_color;
      }

    }
  }
  .popover-title{

    .text-primary{
      color: $orange_color;
    }
  }

  .popover-content{
    background-color: #fff !important;
  }

  .clockpicker-tick{
    -webkit-transition: all .5s;
    transition: all .5s;

    /*background-color: rgba($orange_color, 0.5);*/
    /*font-weight: 600;*/

    &:hover{
      /*color: #fff;*/
    }
  }

  .clockpicker-tick.active, .clockpicker-tick:hover{
    background-color: $orange_color;
  }
}

.intl-tel-input{
  width: 100%;
}
.iti-flag {
  background-image: url("../img/flags.png") !important;
}

.flag-container{
    max-height: 45px;
}

/*Welcome Blade OUR TEAM SECTION*/
#our_team{
  h2{
    text-align: right;
  }
}

.our_team{
  padding: 20px 0;

  .our_team_photo_container{
    height: 180px;
    width: 180px;

    margin: 0 auto;
    &-sm {

    }

    .our_team_photo{
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 180px;
      width: 180px;
      /*margin: 50px;*/

      -webkit-transition: all 1s;

      transition: all 1s;
      &-sm {

      }
    }

    .our_team_photo_overlay{
      top: 0;
      height: 180px;
      width: 180px;
      position: absolute;
      z-index: 3;
      background-color: rgba(0,0,0,0);

      -webkit-transition: all 1s;

      transition: all 1s;
      &-sm {
        height: 120px;
        width: 180px;
      }
    }

    &:hover{

      .our_team_photo{
        border-radius: 50%;
      }

      .our_team_photo_overlay{
        background-color: rgba(0,0,0,0.2);
        border-radius: 50%;
      }
    }
  }

  .our_team_info{
    text-align: center;
    margin-top:10px;
    margin-bottom:15px;

    span{
      font-weight: 600;
      line-height: 1.2em;
      i{
        font-weight: 400;
        font-size: 0.8em;
        padding: 0 10px;
        border-bottom: 1px solid #ddd;
      }
    }
    p{
      padding: 10px 20px;
      /*text-align: left;*/
    }
  }
}

/* SEARCH PAGE*/
.menu_margin {
  margin-top: 84px;
}

#mini-search {

  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height .5s ease-in-out;
  transition: max-height .5s ease-in-out;

  &.show {
    max-height: 100%;
    /*transition: max-height 500ms ease-in-out;*/
  }

  &.overflow { overflow: initial; }

}

.search_container {
  width: calc(100% + 15px);
  margin-left: -15px;
  background: #fff;
  z-index: 2;
  position: relative;
  button { margin-bottom: 0; }
  .results_header {
    &:hover {
      border-color: rgba(255,170,102,0.3);
      color: #ff7100;
      background: transparent;
      cursor: default;
    }
  }

  .serach_nav {

    .nav {
      border: 0;
      padding: 0;
      margin: 0;
    }

    li {
      border: 0;
      padding: 0;
      margin: 0;
      text-align: center;
      &.active,
      &:hover {
        border: 0;
        a {
          border: 0;
          border-top: 3px solid $orange_color;
          background: $orange_color;
          color: #fff;
          -webkit-transition-duration: .3s;
                  transition-duration: .3s;
        }
      }
      a {
        font-size: 1em;
        text-transform: uppercase;
        color: $grey_color;
        border: 0;
        border-top: 3px solid rgba($orange_color, .3);
        border-radius: 0;
        padding: 12px;
        margin: 0;
        -webkit-transition-duration: .3s;
                transition-duration: .3s;
      }
    }

  }

  form {
    padding: 15px;
    .form-group {
      & > label {
        font-weight: 600;
      }
    }
    .radio_input {
      & > label {
        font-size: .94em;
      }
    }
  }

}

.search-item-photo-container.photo-container-types{
    background-color: transparent;
}

.types-of-transports{
    
    padding: 5px !important;
    
    .offer-item-employee-detail{
        float: none;
        display: inline-block;
        width: 100%;
        text-align: center;
        
    }
    
    .avatar.circle{
        max-width: 100%;
        border-radius: 0%;
        background-color: transparent;
            
            img{
                width: 50px;
                height: 50px;
            }
    }
    
    .offer-item-transport{
        padding-bottom: 10px !important;
    }
    
}


.check_element {
  margin-top: 30px;
}

.filter_container {
  min-width: 210px;
  width: 20%;
  padding: 12px 45px 0 15px;
  background: #fff;
  border-right: 2px solid $orange_color;
  z-index: 20;
  position: fixed;
  -webkit-transform: translateX(calc(-100% - 32px));
          transform: translateX(calc(-100% - 32px));
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
  float: left;

  @media all and (max-height: 755px){
    width: 75%;
    font-size: 80%;
    padding: 10px 15px 0 5px;
  }

  &.fixed {
    position: fixed;
    top: 79px;
  }

  .additional_shadow {
    webkit-box-shadow: inset 0px -1px 1px 0px rgb(221, 221, 221);
    box-shadow: inset 0px -1px 1px 0px rgb(221, 221, 221);
  }

  &.show {
    -webkit-transition: -webkit-transform 500ms ease-in-out;
    transition: -webkit-transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    form {
      overflow: initial;
    }
  }

  form {
    overflow: hidden;
    .form-group, .row {
      & > label {
        font-weight: 600;
      }
    }
    .small-margin {
      margin-bottom: 8px;
    }
  }

}

.result_container {
  width: 65%;
  .result_list {
    padding-top: 32px;
    padding-left: 10px;
  }
}

.map_container {
  z-index: 10;
  width: 35%;
  position: absolute;
  top: 5px;
  right: -15px;
  &.fixed {
    position: fixed;
    top: 87px;
    right: 0px;
    #show-map{
        top: 80px;
    }
  }
  .map {
    width: 100%;
    height: 100%;
    position: relative;
  }
  iframe {
    -webkit-transition: height 400ms ease-in-out;
    transition: height 400ms ease-in-out;
  }
  #show-map {
    width: 32px;
    padding: 34px 0;
    color: #fff;
    line-height: 32px;
    text-align: center;
    background: $grey_color;
    position: absolute;
    left: -32px;
    top: 0;
    cursor: pointer;
    display: none;
    &.active {
      background: $orange_color;
    }
  }
}
.resizeMap{
  position: absolute;
  top: 50%;
  z-index: 11;
  height: 50px;
  width: 20px;
  background-color: $orange_color;
  left: -10px;
  color: #fff;
  line-height: 50px;
  cursor: pointer;
  -webkit-transition: all .5s;
  transition: all .5s;

  i{
    line-height: 50px;
    width: 20px;
    text-align: center;
  }

  &:hover{
    background-color: $grey_color;
  }
}
.resizeMap.resizeMap_back{
  /*background-color: $grey_color;*/
  left: 0;
}

.noUi-horizontal {
  height: 8px !important;
  .noUi-handle {
    width: 20px !important;
    height: 20px !important;
    top: -7px !important;
    border-radius: 50% !important;
  }
}
.noUi-connect {
  background: $orange_color !important;
}

#show-filters {
  width: 32px;
  padding: 12px 0 115px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  background: #424C50;
  position: absolute;
  right: -32px;
  top: 0;
  cursor: pointer;

  &.active {
    background: $orange_color;
  }

  &.show-filters-down{
      top: 49px;
  }

  .text {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    -webkit-transform-origin: left top 0;
            transform-origin: left top 0;
    display: block;
    float: left;
    bottom: 0;
    position: absolute;
    text-transform: uppercase;
  }
}

.additional-info{

  width: 100%;

  .additional-photo {
    height: 22px;
    display: inline;
    line-height: 22px;
    vertical-align: middle;
    margin: 0 5px 5px 8px;
  }

  .additional-text {
    line-height: 20px;
    display: inline;
    vertical-align: middle;
    /*padding-left: 15px;*/

    span{
      vertical-align: middle;
      display: inline-block;
      max-width: 50%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      -webkit-transition: all .5s;
      transition: all .5s;
    }
  }
}

#purpose {
  /*fix for scrolling issue*/
  margin-top: 20px;
}

#nonlinear {
  margin: 36px 0 0 0;
}

.clients_rev .review_list_container .thumbnail{
  background-size: contain !important;
}

/* BOOKING*/
.booking-form {
  form {
    input:focus { outline: 0; border: 0 !important; border-bottom: 1px solid $orange_color !important; }
    label { font-size: .94em; }
    .form_action {
      span {
        font-size: .84em;
        line-height: 120%;
        a {
          color: $orange_color;
        }
      }
    }

    .booking_tel_container{
      .parsley-errors-list{
        position: absolute;
        width: 100%;
      }
    }
    .booking_checkbox_container{
      position: relative;
      label{
        margin-top: 12px;
      }
      .parsley-errors-list{
        position: absolute;
        margin-top: 25px;
        width: 100%;
      }
    }

  }
  .sign_booking {
    .sign_booking_title {
      font-size: 1.5em;
      line-height: 100%;
      margin: 0 0 24px 0;
    }
    button:first-of-type { margin-top: 13px; }
  }
}

.helper_info_for_traveler {
  padding: 15px;
  background: #efefef;
  font-size: .78em;

  &.top {
    margin-top: 15px;
  }

  &.bottom {
    margin-bottom: 0px;
  }
  div {
    margin: 6px 0 0 0;
  }
}

[for="terms-conditions"] {
  cursor: pointer;
  font-weight: 600;
  line-height: 22px;
  & > div {
    margin-right: 12px;
  }
}

/* SUMMARY*/
.search_modal_book {
  font-family: 'Open Sans', sans-serif;
  .summary_title {
    font-size: 1.5em;
    line-height: 100%;
    margin: 0 0 24px 0;
  }
  .summary_information {
    margin-left: -15px;
    background: #efefef;
    padding: 15px 15px 0 15px;
    width: calc(100% + 30px);
    margin-bottom: 24px;
    .info-box {
      min-height: 45px;
      -webkit-transition-duration: 300ms;
              transition-duration: 300ms;
      &:hover {
        .info-box-icon {
          background-color: $orange_color;
          -webkit-transition-duration: 300ms;
                  transition-duration: 300ms;
          color: #fff;
        }
      }
    }
    .info-box-icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: .84em;
      position: absolute;
      left: 6px;
      top: -6px;
      background: #ddd;
      text-align: center;
      float: none;
      border-radius: 50%;
    }
    .info-box-content {
      margin-left: 15px;
      font-size: .84em;
      line-height: 130%;
    }
  }
  .modal-footer {
    border: 0 !important;
    margin: 0 0 -15px -15px;
    width: calc(100% + 30px);
  }
}

.summary_information .info-box.info-box-action{
    cursor: pointer;
} 

.booking-form .modal-footer{
  border-top:0;
}

.details_book {
  .offer_section {
    .points {
      background: #efefef;
      margin-left: -15px;
      width: calc(100% + 30px);
      padding: 15px 30px 0 30px;
      .point {
        display: inline-block;
        margin: 0 12px 12px 0;
        &:not(.main){
          .point_name {
            font-size: .9em;
            color: rgba(0, 0, 0, .7);
          }
          .datetime {
            font-size: .56em;
          }
        }
      }
      .point_name {
        font-size: 1.2em;
        line-height: 100%;
        margin: 0 0 3px 0;
        color: #000;
      }
      .datetime {
        font-size: .84em;
        line-height: 100%;
        margin: 0;
        display: block;
      }
    }
  }
}

.transport_image {
  max-width: 60px;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: #efefef;
  text-align: center;
  img { width: 90%; height: auto; }
}

.site-header-front-page{

  .choose_language_container{

    .choose_language{

      border-bottom: 0 !important;

      &:hover{
        /*background-color: #ddd !important;*/
        /*background-color: #eee !important;*/
        border-bottom: 0 !important;
      }

      span{
        line-height: 50px;
        opacity: 0;
        margin-left: -7px;
      }

      img{
        width: 24px;
      }

    }

    ul{
      background-color: #eee !important;
      border-radius: 0 !important;
      padding: 10px 0;
      width: 250px;
      -webkit-transition: all .5s;
      transition: all .5s;
      box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.3), -1px 2px 2px 0px rgba(0,0,0,0.3);
      border: 0 !important;

      /*position: absolute;*/
      right: 0;
      left: auto;

      p{
        font-weight: 600;
        padding-bottom: 15px;
        text-align: center;
        line-height: 1em;
      }

      .single-language{
        width: 50%;
        float: left;
        padding: 5px 5px 5px 7px;
        -webkit-transition: all .5s;
        transition: all .5s;

        &:hover{
          background-color: $light_grey_color;

          span{
            color: #fff;
          }
        }


        a{
          padding-right: 10px;
          line-height: 1em !important;


          span{
            margin-left: 5px;
            color: #333;
            font-size: .8em;
          }

          &:hover{
            background: 0 !important;
            border-bottom: 0 !important;
          }

          img{
            width: 20px;
          }
        }
      }
    }

    &.open{
      .choose_language{
        background-color: #eee !important;
        box-shadow: 1px 2px 2px 0px rgba(0,0,0,0.3);
      }
    }
  }

}

.sweet-alert button{
    padding: 10px 32px !important;
}

.summary_transport_valid{
    font-size: 1.5em;
    font-weight: 600;
    color: red;
    display: none;
}

.summary_time_valid{
    font-size: .9em;
    font-weight: 600;
    color: red;
    display: none;
    margin-top: 2px;
}

#contact_form{    
    li[class*="parsley-"]{
        color: #fff;
    }
    textarea[class*="parsley-error"] {
        border: 1px solid red !important;
    }
}

.contact-validate-modal{
    button{
        &.confirm{
            background-color: $orange_color !important;
            padding: 13px 45px !important;
            margin: 15px 5px 0 5px !important;
            border-radius: 0 !important;
            transition: all .5s;
            
            &:hover{
              background-color: $light_orange_color !important;
            }
        }
    }
}


@media all and (min-width: 1200px) and (max-width: 1600px){
  .mk_register_and_add{

    button{

      /*font-size: .9em !important;*/
    }
  }
}

@media all and (min-width: 1200px) and (max-width: 1480px){
  .mk_register_and_add{

    button{

      /*font-size: .8em !important;*/
    }
  }
}

@media all and (min-width: 1200px) and (max-width: 1300px){
  .mk_register_and_add{

    button{

      /*font-size: .7em !important;*/
    }
  }
}


@media only screen and (max-width: 1550px) {
  .partners {
    .header_c {
      background: url(../img/partner-guy2.png) 75% bottom no-repeat;
    }
  }
}

@media screen and (max-width:1366px){
  .vert_middle.offerer-register{
    padding-top: 52px;

    .form_container{
      padding-bottom: 0;
      padding-top: 5px;
    }

    .input_group.offerer_checkbox_inputs{
      font-size: 0.9em;
    }
  }
  .panel_header{
    font-size: 1.2em;
  }

  .mk-form-outer-container ul.nav.nav-tabs li a{
    font-size: 1em;
  }
  .mk-form-outer-container .mk-form {
    input, select{
      font-size: 0.8em;
      padding: 6px 6px;
    }
    label{
      font-size: 0.9em;
    }
    .mk_radio_inputs label{
      font-size: 0.9em;
    }

    .local_rent_type_select{
      .select2-selection.select2-selection--single{
        padding: 5px !important;
        border-radius: 0;
        height: 29px;
        line-height: 29px;
        font-size: 0.8em;
      }

      .select2-selection__rendered{
        /*line-height: 43px;*/
        padding-left: 0;
      }

      .select2-selection__arrow{
        top: 2px;
      }
    }

  }
  .offerer-register-form label{
    font-size: 0.9em;
  }
  .offerer-register-form input, .offerer-register-form select{
    font-size: 0.8em;
    padding: 6px 6px;
  }

  .offerer_radio_inputs {
    font-size: 0.9em;
  }
  .register_search_button{
    font-size: 0.9em !important;
    padding: 6px !important;

  }

  .offerer-register-form .mk_submit_container button i{
    font-size: 1em;
  }
  .mk-form-outer-container .mk-form .mk_submit_container button::before{
    font-size: 1.2em;
    top: 0.7em;
  }
  .mk_required{
    font-size: 0.7em !important;
  }

  .register_information h3{
    font-size: 0.9em
  }
  .register_information ul{
    font-size: 0.8em;
  }

  .vert_middle.login_form form input, .vert_middle.login_form form select{
    font-size: 0.8em;
    padding: 6px 6px;
  }
  .forgot_password{
    font-size: 0.9em;
  }
  .vert_middle.login_form form label{
    font-size: 0.9em;
  }

  .vert_middle.login_form form .mk_required{
    padding: 12px 0;
  }
  .vert_middle.login_form .alert.alert-danger{
    font-size: 0.9em;
  }

  .transport_information h3{
    font-size: 2em;
  }
}
@media only screen and (min-width: 1115px) and (max-width: 1550px){
  #site-subname{
    display: none;
  }
}

@media only screen and (min-width: 1250px) {
  #site-header {
    #nav-toggle { display: none; }
    #site-navigation { display: block !important; }
  }
}

@media only screen and (max-width: 1250px) {

  #site-header {

    /*NAVIGATION*/
    .navbar-toggle {

      display: block;
      background: transparent;
      border-radius: 0;
      z-index: 21;

      .icon-bar {
        background: $light_grey_color;
      }

    }

    #site-navigation {

      & {

        overflow: scroll;
        display: block;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background: rgba($light_grey_color, .9) !important;

        .nav {
          float: none !important;
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          margin: 0;
          /*margin-top: 82px;*/

          & > li {
            float: none;
            width: 100%;
            height: 51px;
            text-align: center;

            &.panel_icon {
              width: calc(25% - 4px);
              display: inline-block;

              &.show_login{
                  width: 100%;
              }

              &.dropdown.user-menu{
                  width: 100%;
              }
            }

            &.panel_icon.panel_icon_instagram{
              margin-left: 0 !important;
            }

            &.facebook {
              margin-top: 15px;
              a {
                background: url(../img/facebook_white.png) center center no-repeat;
                background-size: 100%;
              }
            }

            &.instagram {
              a {
                background: url(../img/insta_white.png) center center no-repeat;
                background-size: 100%;
              }
            }

            a {

              font-size: 1.15em;
              color: #fff;

            }

          }

        }

      }

    }

  }

  #site-header #site-navigation .nav>li.panel_icon.open {
     background-color: transparent;

     #user_menu{
         position: absolute;
         left: 50% !important;
         -webkit-transform: translateX(-50%);
                 transform: translateX(-50%);
         margin-bottom: 10px;
     }
}

  .site-header-front-page{

    .choose_language_container{

      .choose_language{

        border-bottom: 0 !important;
        margin-top: 15px;

        &:hover{
          /*background-color: #ddd !important;*/
          /*background-color: #eee !important;*/
          border-bottom: 0 !important;
        }

        span{
          line-height: 50px;
          opacity: 0;
          margin-left: -7px;
          display: none;
        }

        img{
          width: 40px;
        }
      }

      ul{
        margin-top: 10px !important;
        background-color: transparent !important;
        border-radius: 0 !important;
        padding: 10px 0;
        width: 100%;
        -webkit-transition: all .5s;
        transition: all .5s;
        box-shadow: none;
        border: 0 !important;

        position: static !important;
        right: 0;
        left: auto;

        p{
          font-weight: 600;
          padding-bottom: 15px;
          text-align: center;
          line-height: 120%;
          color: #fff;
          font-size: 1.5em;
        }

        .single-language{
          width: 50%;
          float: left;
          padding: 5px 5px 5px 7px;
          -webkit-transition: none;
          transition: none;
          border-bottom: 1px solid transparent;
          text-align: center;

          &:hover{
            background-color: transparent;
            border-bottom: 1px solid $light_orange_color;

            span{

            }
          }


          a{
            padding-right: 10px;
            line-height: 1em !important;


            span{
              font-size: .5em;
              color: #fff;
            }

            &:hover{
              background: 0 !important;
              border-bottom: 0 !important;
            }

            img{
              width: 24px;
              vertical-align: middle;
            }
          }
        }
      }

      &.open{
        .choose_language{
          background-color: transparent !important;
          box-shadow: none;
        }
      }
    }

  }

}


@media screen and (max-width:991px){

      /* VIDEO */
  .video_container {

    .video_content {

      width: 60%;

    }

  }

  .transport_information {
    background: none;
    .transport_inf_list {
      li {
        margin: 35px 0 !important;
      }
    }
  }
  .who_we_are {
    &:after {
      content: none;
    }
  }

  .partners {
    .header_c {
      background: none;
    }
  }

  .in_numbers {
      
      padding: 10px 0;

    .inn_carousel {

      .headers {

        h2 {
          font-size: .8em;
        }

        h3 {
          font-size: 3em;
          padding: 5px 0;
        }

      }

    }

  }

  .section.who_we_are{
    .vert_middle{
      p{
        padding: 20px;
        margin-bottom: 30px;
      }
    }
  }

  .transport_information h3{
    font-size: 2em;
  }

  .resizeMap{
    display: none;
  }

  .contact h2 {
    font-size: 2em;
    margin: 25px 0 25px 0;
}
  .vert_middle.login_form{
    padding-top: 72px;
  }

  .map_container {
    width: calc(100% - 32px);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: -webkit-transform 500ms ease-in-out;
    transition: -webkit-transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out;
    transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    z-index: 100;
    &.show {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: -webkit-transform 500ms ease-in-out;
      transition: -webkit-transform 500ms ease-in-out;
      transition: transform 500ms ease-in-out;
      transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
    }
    #show-map {
      display: block;
    }
  }
  .result_container {
    width: 98%;
  }


  .contact {
    margin-top: 0;
    padding: 40px 0;
  }

  .partners {
    padding: 0 0 160px 0;
  }

  .partners .header_c{
      padding: 10px 0 35px 0;
  }

  .clients_rev .header_c{
      margin-bottom: 50px;
  }

  .section{
      height: initial;
      min-height: initial;
  }

  .transport_information{
      padding: 35px 0 15px 0;
  }

  #purpose{
      padding: 30px 0 50px 0;

      .slick-slide{
          height: initial;
          min-height: 50% !important;
      }

      .slider_dots{
          top: 95%;
      }

  }


  .mk-form-outer-container .mk-form{
      margin-top: 10px;
  }

  .mk-form-outer-container .search_additional_info{
      max-width: 100%;
  }

  .mk-form-outer-container .search_additional_info p{
      text-align: center;
  }

}


@media only screen and (max-width: 786px) {

  /* VIDEO */
  .video_container {

    .video_content {

      width: 80%;

    }

  }

}

@media only screen and (max-width: 767px) {

  .check_element {
    margin-top: 0;
  }

  .clients_rev,
  .partners {

    .nav {
      margin: 20px 0 0 0;
    }

  }

  .third_section {

    .video_thumbnail {
      float: none;
      width: 100%;
      margin: 0 0 15px 0;
    }
    .hw_information {
      max-width: 100%;
      width: 100%;
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      float: none;

      .headers {
        max-width: 100%;
      }

      .action {
        h3 {
          max-width: 100%;
          width: 100%;
        }
      }

    }

  }

  .in_numbers {

    .inn_carousel {

      padding: 20px 0;

      .slick-current {
        box-shadow: 0px 0px 40px -20px rgba(0,0,0,0.75);
      }

    }

  }

  .vert_middle.select_user_type{
    padding-top: 52px;
  }

  .mk-xs-padding{
    padding-left: 10px;
    padding-right: 10px;
  }

  .mk_submit_container{

    button::before {
      margin-left: 1em;
    }

  }

  .benefits-of-reg{
    margin-top: 30px;
  }

  .third_section .hw_information .action a{
      min-width: 100%;
  }

  .second_section{
    .slide_content{
      max-width: 80%;
    }
  }

  .mk-form-outer-container .search_additional_info.search_additional_info_local{
      margin-top: 10px;
  }

}

@media only screen and (max-width: 565px) {

  #site-header {

    #site-subname { display: none; }

  }

}

@media only screen and(max-width: 480px){
  .second_section{
    .slider_dots{
      top: 95%;
    }
  }

  .transport_information h3{
    font-size: 1.8em;
    line-height: 110%;
  }


  .site-header-front-page{

    .choose_language_container{

      ul{

        p{

          font-size: 1.1em;
        }

        .single-language{
          width: 100%;

        }
      }

    }

  }

  .transport_information .transport_inf_list li h2{
      font-size: 1.2em;
      letter-spacing: 1px;
      text-align: center;
  }
  .transport_information .transport_inf_list li p{
      text-align: center;
  }

  .clients_rev h2,
  .partners h2{
      font-size: 2.5em;
  }



  .clients_rev .nav li,
  .partners .nav li {
    margin: 0 5px;
    width: calc(50% - 10px);

    a{
        padding-right: 0;
        padding-left: 0;
    }
}

  .second_section .slider_dots{
      top: 92%;
  }

  .second_section .slider_dots li{
      margin: 0 5px;
  }

  /*//*/
}

@media screen and (max-width: 360px) {

    .who {

        h2 {

            font-size: 2em;

        }

    }

    .second_section .slider_dots{
        top: 98%;
    }

    .clients_rev h2,
    .clients_rev h2,
    .in_numbers .section_header h2,
    .third_section .section_header{
        font-size: 2em;
    }

    .second_section h2 {
    font-size: 1.3em;
    }
    
    #site-header #site-navigation .nav{
        margin-top: 50px;
    }

}
@media screen and (max-height:740px){
  .row.shrinkable {
    width: 50%;
    float: left;
    &-nofloat {
      width: 50%;
      float: none;
    }
    .form-group {
      width: 100%;
    }
  }

}

@media screen and (max-height:640px){

  #site-navigation{
      .nav.navbar-nav.navbar-custom-menu.pull-right{
          /*padding-top: 100px;*/
      }
  }  

}


@media screen and (max-height:480px){

  .vert_middle.select_user_type{
    padding-top: 52px;

    .form_container{
      padding: 5px;
    }
  }

  #site-navigation{
        .nav.navbar-nav.navbar-custom-menu.pull-right{
            /*padding-top: 150px;*/
        }
    }


}


@media only screen and (min-width: 1170px) {
  .transport_information{
    /*margin-top: -5%;*/
  }
}

/*@media only screen and (min-width: 1366px) {
.transport_information{
margin-top: -15%;
}
}*/


@media only screen and (min-width: 1920px) {


  .transport_inf_list {

    li {

      &:nth-of-type(2) {
        margin: 120px 0 120px 0;
      }
    }
  }
}

@media only screen and (min-width: 1920px) {
    .transport_information{
    /*margin-top: -15%;*/
  }
}

#booking-page {
  margin-top: 101px;
}

.private_commercial {
  span {
    line-height: 22px;
    padding-left: 5px;
  }
}

.use-my-location-alert {
  color: red;
}



.header-transport-icons {
  display: flex;
  justify-content: center;
  max-height: 50px;
  padding-top: 70px
}

.initialCloud {
  position: absolute;
  display: flex;
  left: 0px;
  animation: moveInitial 25s linear 1s infinite;
  width: 1760px;
  img {
    width: 40px;
  }
}

.firstCloud {
  position: absolute;
  display: flex;
  left: -1760px;
  animation: moveFirst 25s linear 1s infinite;
  width: 1760px;
  img {
    width: 40px;
  }
}



@-webkit-keyframes moveInitial {
  from {
    left: 0px;
  }
  to {
    left: 1760px;
  }
}

@-webkit-keyframes moveFirst {
  from {
    left: -1760px;
  }
  to {
    left: 0px;
  }
}

.font-size-larger {
  font-size: larger !important;
}

.font-size-x-small {
  font-size: x-small !important;
}

.button-0-padding {
  padding: 0px !important;
}

.padding-top-10 {
  padding-top: 10px;
}